import { Browsers, Container, Logo, Wrapper } from "./styles";

const Playground = () => {
    return (
        <Container>
            <Wrapper>
            <Logo />
            <Browsers />
            </Wrapper>
        </Container>
    )
}

export default Playground;