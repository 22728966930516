import styled from 'styled-components';

import logo from '../../assets/images/GANDAYA.png'
import browsers from '../../assets/images/browsers.png'

const Container = styled.div`
    position: fixed;    
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled.div`
    position: fixed;    
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`

const Logo = styled.div`
    padding-top: 59%;
    width: 80%;

    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

const Browsers = styled.div`
    width: 80%;
    height: 80px;

    background-image: url(${browsers});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;    
`

export {
    Container,
    Wrapper,
    Logo,
    Browsers
}
